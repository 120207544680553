import React from 'react'
import Zsebmano from '../../../assets/zsebmano-logo.png'
import Outside from '../../../assets/kint-felirat.png'
import Inside from '../../../assets/bent-felirat.png'
import Tram from '../../../assets/busz-felirat.png'
import Walk from '../../../assets/gyalog-felirat.png'
import Info from '../../../assets/tudnivalo.png'
import Intro from '../../../assets/bevezeto.png'
import { ROUTE_TEMPLATE as TALE_LIST_ROUTE_TEMPLATE } from '../tale-list/interfaces'
import { ROUTE_ID as INFO_ROUTE_ID } from '../info/interfaces'
import { ROUTE_ID as ONBOARDING_ROUTE_ID } from '../onboarding/interfaces'

import { useHistory } from 'react-router-dom'
import TaleCategory from '../../../model/domain/tale-category'
import {
  Container,
  Footer,
  Header,
  Logo,
  Menu,
  StyledButton,
  StyledImg,
  StyledInput,
} from './style'

const HomePage = () => {
  const history = useHistory()

  return (
    <Container>
      <Header>
        <Logo alt="Zsebmanó" src={Zsebmano} />
      </Header>
      <Menu>
        <StyledInput
          type="image"
          alt="Várakozunk kint"
          src={Outside}
          onClick={() => history.push(TALE_LIST_ROUTE_TEMPLATE(TaleCategory.OUTSIDE))}
        />
        <StyledInput
          type="image"
          alt="Várakozunk bent"
          src={Inside}
          onClick={() => history.push(TALE_LIST_ROUTE_TEMPLATE(TaleCategory.INSIDE))}
        />
        <StyledInput
          type="image"
          alt="Tömegközlekedünk"
          src={Tram}
          onClick={() => history.push(TALE_LIST_ROUTE_TEMPLATE(TaleCategory.COMMUTING))}
        />
        <StyledInput
          type="image"
          alt="Gyaloglunk"
          src={Walk}
          onClick={() => history.push(TALE_LIST_ROUTE_TEMPLATE(TaleCategory.WALKING))}
        />
      </Menu>
      <Footer>
        <StyledButton onClick={() => history.push(ONBOARDING_ROUTE_ID)}>
          <StyledImg src={Intro} alt="Bevezető" />
          Bevezető
        </StyledButton>
        <StyledButton>
          <StyledImg src={Info} alt="Tudnivalók" onClick={() => history.push(INFO_ROUTE_ID)} />
          Tudnivalók
        </StyledButton>
      </Footer>
    </Container>
  )
}

export default HomePage
