import React from 'react'
import FramePage from './components/frame-page'
import './App.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import TaleList from './components/screens/tale-list'
import { ROUTE_ID as TALE_LIST_ROUTE_ID } from './components/screens/tale-list/interfaces'
import Tale from './components/screens/tale'
import { ROUTE_ID as TALE_ROUTE_ID } from './components/screens/tale/interfaces'
import HomePage from './components/screens/home-page'
import { ROUTE_ID as INFO_ROUTE_ID } from './components/screens/info/interfaces'
import Info from './components/screens/info'
import { ROUTE_ID as ONBOARDING_ROUTE_ID } from './components/screens/onboarding/interfaces'
import Onboarding from './components/screens/onboarding'

function App() {
  return (
    <div className="App">
      <FramePage>
        <BrowserRouter>
          <Switch>
            <Route path={TALE_LIST_ROUTE_ID} component={TaleList} />
            <Route path={TALE_ROUTE_ID} component={Tale} />
            <Route path={INFO_ROUTE_ID} component={Info} />
            <Route path={ONBOARDING_ROUTE_ID} component={Onboarding} />
            <Route path={'/'} component={HomePage} />
          </Switch>
        </BrowserRouter>
      </FramePage>
    </div>
  )
}

export default App
