import React from 'react';
import Logo from '../../assets/logo.png'
import EuLogo from '../../assets/eu-logo.svg'
import HvLogo from '../../assets/hv-logo.svg'

import './style.css';

const FramePage = (props: any) => (
  <>
    <section className='only-desktop' id='hero'>
      <div id='hero-inner'>
        <div id='hero-inner-cover'>
          <div id='hero-center'>
            <div id='hero-logo'>
              <a href='https://plukkido.hu'>
                <img alt='Plukkido' src={Logo} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id='phone-container'>
        <div id='phone-frame'>
          <div className='main-container'>
            {props.children}
          </div>
        </div>
      </div>
    </section>

    <section className='only-mobile' id='mobile-full-screen'>
      {props.children}
    </section>

    <footer className='only-desktop' >
      <div className='footer-inner'>
        <div className='footer-text'>
          <p className=''>COPYRIGHT © 2018-2020 PLUKKIDO</p>
          <p className=''>PLUKKIDO KFT., 1155 BP RÁKOS ÚT 243., 8000 SZÉKESFEHÉRVÁR HAD UTCA 1-3</p>
          <div>
            <a href='https://plukkido.hu/aszf/' className='text-link'>ÁSZF</a>
            <span className='circle'>&#9702;</span>
            <a href='https://plukkido.hu/adatkezelesi-tajekoztato/' className='text-link'>Adatkezelési tájékoztató</a>
            <span className='circle'>&#9702;</span>
            <a href='https://plukkido.hu/cookie-szabalyzat/' className='text-link'>Cookie szabályzat</a>
            <span className='circle'>&#9702;</span>
            <a href='https://plukkido.hu/kapcsolat/' className='text-link'>Kapcsolat</a>
          </div>
          <a href='https://www.hiventures.hu'>
            <img alt='Hiventures' className='hv-logo' src={HvLogo} />
          </a>
        </div>
      </div>

      <div style={{position: 'relative'}}>
        <img alt='Széchenyi' className='eu-logo' src={EuLogo} />
      </div>

    </footer>
  </>
)

export default FramePage