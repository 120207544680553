import TaleCategory from '../domain/tale-category'
import Tale from '../domain/tale'

const DATA_URL = `${process.env.PUBLIC_URL}/data/zsebmano.json`

const CATEGORY_MAP: Record<string, TaleCategory> = {
  ['Várakozás kültéren']: TaleCategory.OUTSIDE,
  ['Várakozás beltéren']: TaleCategory.INSIDE,
  ['Tömegközlekedés']: TaleCategory.COMMUTING,
  ['Gyalogos közlekedés']: TaleCategory.WALKING,
}

class TaleApi {
  cachedData: Tale[] = []

  async getTalesByCategory(category: TaleCategory): Promise<Tale[]> {
    await this.fetchData()

    return this.cachedData.filter((tale: any) =>
      tale.categories.find((taleCategory: TaleCategory) => taleCategory === category)
    )
  }

  async getTaleById(id: number): Promise<Tale | undefined> {
    await this.fetchData()

    return this.cachedData.find((tale: any) => tale.id === id)
  }

  async fetchData() {
    if (!this.cachedData || !this.cachedData.length) {
      const response = await fetch(DATA_URL)
      const data = await response.json()
      if (data && data.contents) {
        this.cachedData = data.contents.map(this.convertTaleDtoToTale)
      }
    }
  }

  convertTaleDtoToTale = (taleDto: any) => ({
    id: taleDto.id,
    title: taleDto.title,
    content: taleDto.content,
    instruction: taleDto.instruction,
    categories: taleDto.categories
      .filter((taleCategory: any) => CATEGORY_MAP[taleCategory.name])
      .map((taleCategory: any) => CATEGORY_MAP[taleCategory.name]),
  })
}

export default new TaleApi()
