import React from 'react'

import {
  InfoHeaderButton,
  InfoHeaderButtonsContainer,
  InfoHeaderContainer,
  InfoHeaderTextsContainer,
} from './style'
import {
  SecondarySmallHeaderContainer,
  TextContainer,
  BackgroundContainer,
} from '../../styled-components'
import Card from '../../card'
import { useHistory } from 'react-router-dom'

const Info: React.FC = () => {
  const history = useHistory()

  return (
    <BackgroundContainer>
      <Card title="Tudnivalók" onBottomButtonPressed={() => history.goBack()}>
        <InfoHeaderContainer>
          <InfoHeaderButtonsContainer>
            <InfoHeaderButton
              to={{ pathname: 'https://plukkido.hu/adatkezelesi-tajekoztato/' }}
              target="_blank"
            >
              Adatvédelem
            </InfoHeaderButton>
            <InfoHeaderButton to={{ pathname: 'https://plukkido.hu/' }} target="_blank">
              Plukkidóról
            </InfoHeaderButton>
          </InfoHeaderButtonsContainer>
          <InfoHeaderTextsContainer>
            <div>© Plukkido</div>
            <div>2018-{new Date().getFullYear()}</div>
          </InfoHeaderTextsContainer>
        </InfoHeaderContainer>
        <div>
          <SecondarySmallHeaderContainer>
            Mi az a Zsebmanó alkalmazás?
          </SecondarySmallHeaderContainer>
          <TextContainer>
            A Zsebmanó alkalmazás játékos feladataival elűzi a gyerekek unalmát gyaloglás, kültéri
            és beltéri várakozás vagy tömegközlekedés esetén. Összekapcsolja a mesevilág történéseit
            a gyermek valós élményeivel. Kalandossá teszi a hétköznapi helyzeteket, amivel segít a
            szülőknek lekötni a gyerekek figyelmét, képzeletét. A Manó Hírmondó minden hét elején, a
            Manó Hírmondó Extra pedig minden hónap végén tájékoztatja a gyerekeket a manóföldi
            eseményekről. A hírek regisztrációt követően érhetők el.
          </TextContainer>
          <SecondarySmallHeaderContainer>
            Kiknek ajánlott az alkalmazás?
          </SecondarySmallHeaderContainer>
          <TextContainer>3 és 8 éves kor közötti gyerekeknek és szüleiknek.</TextContainer>
          <SecondarySmallHeaderContainer>
            Milyen helyzetekben használható?
          </SecondarySmallHeaderContainer>
          <TextContainer>
            Beltéren és kültéren való várakozás közben, hosszú gyaloglások, vagy tömegközlekedés
            alkalmával. Ha a gyermek unatkozik, nyűgös vagy egy kis fantáziavilágban való
            barangolásra vágyik. A négy helyzet közül az alkalmazás főoldalán lehet választani.
          </TextContainer>
          {/*<AlternateSmallHeaderContainer>Mit kapok, ha regisztrálok?</AlternateSmallHeaderContainer>*/}
          {/*<TextContainer>*/}
          {/*  A játékos feladatokon túl, elérhetővé válik a manóvilági történéseket tartalmazó heti Manó*/}
          {/*  Hírmondó és a hóvégi Manó Hírmondó Extra. Így a gyermek megtudhatja, hogy unalma*/}
          {/*  elűzésével miben lesz a manók segítségére.*/}
          {/*</TextContainer>*/}
          {/*<AlternateSmallHeaderContainer>*/}
          {/*  Mi az a Manó Hírmondó? Mit olvashatunk benne és hol érhető el?*/}
          {/*</AlternateSmallHeaderContainer>*/}
          {/*<TextContainer>*/}
          {/*  Minden héten az első használat során a Manó Hírmondóban Zsebmanó megüzeni a gyerekeknek,*/}
          {/*  hogy milyen aktuális zűrzavar támadt Manóföldön, aminek megoldásához egész héten az*/}
          {/*  embergyerekek segítségét kéri. Az alkalmazás főoldalán a Hírmondó ikonra kattintva,*/}
          {/*  bármikor visszaolvasható az aktuális heti hír.*/}
          {/*</TextContainer>*/}
          {/*<AlternateSmallHeaderContainer>*/}
          {/*  Mi az a Manó Hírmondó Extra? Hogyan jutunk hozzá?*/}
          {/*</AlternateSmallHeaderContainer>*/}
          {/*<TextContainer>*/}
          {/*  A Manó Hírmondó Extra egy hó végén megjelenő újság, amiben regisztrált felhasználóink*/}
          {/*  olvashatnak Manóvilág híreiről és természetesen azokról a zűrzavarokról, aminek*/}
          {/*  megoldásához Zsebmanó a gyerekek segítségét kérte. A Manó Hírmondó Extrát emailben küldjük*/}
          {/*  játékosainknak.*/}
          {/*</TextContainer>*/}
          <SecondarySmallHeaderContainer>
            Hogyan kell az unaloműző feladatokat végrehajtani?
          </SecondarySmallHeaderContainer>
          <TextContainer>
            A játékos feladatokban Zsebmanó mindig instruálja a gyerekeket, néha a szülőket is
            arról, hogy mit és miért kell tenniük. A hatás megsokszorozódik, ha a szülő is benne van
            a mókában és beszélget gyermekével az adott feladatról, esetleg vele közösen meg is
            oldja.
          </TextContainer>
          <SecondarySmallHeaderContainer>
            Ez egy fejlesztőjáték? Mit fejleszt?
          </SecondarySmallHeaderContainer>
          <TextContainer>
            A gyerekek fantáziavilága egy csodálatos terep. A játékos feladatokkal töltött idő
            fejleszti többek között a gyerekek kreativitását, képzelőerejét, megfigyelőképességét,
            szókincsét. Sok feladattal lexikális tudása is fejlődik. Az alkalmazás elsődleges célja
            mégis az, hogy a szülők számára kihívást jelentő helyzeteket játékos megoldásokkal tegye
            könnyen kezelhetőbbé, a gyermekek számára pedig élvezetesebbé.
          </TextContainer>
          <SecondarySmallHeaderContainer>
            Mit kell tudni a tervezőkről?
          </SecondarySmallHeaderContainer>
          <TextContainer>
            A Zsebmanó alkalmazás, ahogy a Plukkido többi gyereknevelést segítő eszköze is
            pszichológus és szociálpedagógus tervezők munkája. Az innováció díjas csapat minden
            eszköze a pozitív, játékos kommunikációra épül, elkerülve a nevelésben a büntetést, a
            zsarolást és a negatív fegyelmezést.
          </TextContainer>
        </div>
      </Card>
    </BackgroundContainer>
  )
}

export default Info
