import React, { SyntheticEvent, useRef, useState } from 'react'
import OkImage from '../../assets/ok.png'
import { TextButton, CircleButton, CardContainer, Icon } from '../styled-components'
import {
  InstructionsButtonContainer,
  ContentButtonContainer,
  FlipCardContainer,
  FlipCardContent,
  ButtonContainer,
  GradientBottom,
  RightLeaf,
  LeftLeaf,
  Container,
  FlipCard,
  Header,
  Title,
} from './style'
import { withSize } from 'react-sizeme'

interface CardProps {
  readonly title?: string
  readonly onBottomButtonPressed?: () => void
  readonly instructionsButton?: React.ReactNode
  readonly contentButton?: React.ReactNode
  readonly buttonTitle?: string
  readonly leaves?: boolean
  readonly children?: React.ReactNode
}

const CardContainerWithSizeMe = withSize({ monitorHeight: true })(CardContainer)

const Card: React.FC<CardProps> = props => {
  const {
    onBottomButtonPressed,
    instructionsButton,
    contentButton,
    buttonTitle,
    children,
    leaves,
    title,
  } = props

  const cardRef = useRef<HTMLDivElement>(null)
  const [cardContainerHeight, setCardContainerHeight] = useState<number>()
  const [scrolledToBottom, setScrolledToBottom] = useState<boolean>()

  const scrollVisible =
    !!cardRef &&
    !!cardRef.current &&
    !!cardContainerHeight &&
    cardRef.current.clientHeight < cardContainerHeight

  const onSize = (size: { readonly width: number | null; readonly height: number | null }) => {
    if (size.height) {
      setCardContainerHeight(size.height)
    }
  }

  const onScrolled = (event: SyntheticEvent) => {
    const target = event.target as HTMLTextAreaElement
    const scrollValue = target.scrollTop
    if (
      cardContainerHeight &&
      cardRef.current &&
      scrollValue >= cardContainerHeight - cardRef.current.clientHeight
    ) {
      setScrolledToBottom(true)
    } else {
      setScrolledToBottom(false)
    }
  }

  return (
    <Container>
      <Header title={title}>{title && <Title>{title}</Title>}</Header>
      <FlipCardContainer>
        <FlipCard>
          <FlipCardContent ref={cardRef} onScroll={onScrolled}>
            <CardContainerWithSizeMe onSize={onSize}>{children}</CardContainerWithSizeMe>
          </FlipCardContent>
          {scrollVisible && !scrolledToBottom && <GradientBottom />}
        </FlipCard>
        <ButtonContainer>
          {buttonTitle ? (
            <TextButton onClick={onBottomButtonPressed}>{buttonTitle}</TextButton>
          ) : (
            <CircleButton onClick={onBottomButtonPressed}>
              <Icon src={OkImage} />
            </CircleButton>
          )}
        </ButtonContainer>
      </FlipCardContainer>
      {instructionsButton && (
        <InstructionsButtonContainer>{instructionsButton}</InstructionsButtonContainer>
      )}
      {contentButton && <ContentButtonContainer>{contentButton}</ContentButtonContainer>}
      {leaves && (
        <>
          <LeftLeaf />
          <RightLeaf />
        </>
      )}
    </Container>
  )
}
export default Card
