import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const InfoHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  
  margin-bottom: 24px;
`

export const InfoHeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 60%;
`

export const InfoHeaderTextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 40%;

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: black;
`

export const InfoHeaderButton = styled(Link)`
  width: 150px;
  height: 30px;
  background-color: #8421f4;
  border-radius: 24px;
  border-width: 0px;
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 8px;
 
  
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  color: white;
  text-align: center;
  text-decoration: none;
  
  &:active {
    background-color: rgba(144,19,254,0.8);
  }
`
