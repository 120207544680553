import Tale from '../../../../model/domain/tale'
import React from 'react'
import { Bell, BellContainer } from '../style'
import BellImage from '../../../../assets/bell.png'
import { TertiaryBackgroundContainer } from '../../../styled-components'
import Card from '../../../card'

interface DoneCardProps {
  readonly tale?: Tale
  readonly onTaleFinished: () => void
}

const DoneCard: React.FC<DoneCardProps> = ({ tale, onTaleFinished }) => {
  return (
    <TertiaryBackgroundContainer>
      <Card title={'Gratulálunk'} onBottomButtonPressed={onTaleFinished}>
        {tale && (
          <BellContainer>
            <Bell src={BellImage} />
          </BellContainer>
        )}
      </Card>
    </TertiaryBackgroundContainer>
  )
}

export default DoneCard
