import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { TaleListRouteParamProps } from './interfaces'
import taleApi from '../../../model/api/tale-api'
import Tale from '../../../model/domain/tale'
import { CategoryNameContainer, TaleLink, TaleLinkContainer } from './style'
import TaleCategory from '../../../model/domain/tale-category'
import { ROUTE_TEMPLATE as TALE_ROUTE_TEMPLATE } from '../tale/interfaces'
import Card from '../../card'
import {
  BackgroundContainer,
} from '../../styled-components'

const getLabelForTaleCategory = (category: TaleCategory) => {
  switch (category) {
    case TaleCategory.COMMUTING:
      return 'Tömegközlekedünk'
    case TaleCategory.INSIDE:
      return 'Várakozunk bent'
    case TaleCategory.OUTSIDE:
      return 'Várakozunk kint'
    case TaleCategory.WALKING:
      return 'Gyalogolunk'
    default:
      return ''
  }
}

const TaleList: React.FC = () => {
  const { category: categoryParam } = useParams<TaleListRouteParamProps>()
  const category = categoryParam.toUpperCase() as TaleCategory
  const [tales, setTales] = useState<Tale[]>([])
  const history = useHistory()

  useEffect(() => {
    ;(async function () {
      const newTales = await taleApi.getTalesByCategory(category)
      if (newTales.length) {
        setTales(newTales)
      } else {
        history.goBack()
      }
    })()
  }, [category, history])

  return (
    <BackgroundContainer>
      <Card title="Történeteink" onBottomButtonPressed={() => history.goBack()} buttonTitle={"Vissza"}>
          <CategoryNameContainer>{getLabelForTaleCategory(category)}</CategoryNameContainer>
          {tales.map(tale => (
            <TaleLinkContainer key={tale.id}>
              <TaleLink key={tale.id} to={TALE_ROUTE_TEMPLATE(tale.id)}>
                {tale.title}
              </TaleLink>
            </TaleLinkContainer>
          ))}
      </Card>
    </BackgroundContainer>
  )
}

export default TaleList
