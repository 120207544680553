import Tale from '../../../../model/domain/tale'
import ContentImage from '../../../../assets/content.png'
import React from 'react'
import {
  SecondarySmallHeaderContainer,
  SecondaryBackgroundContainer,
  TextContainer,
  CircleButton,
  Icon,
} from '../../../styled-components'
import Card from '../../../card'

interface InstructionsCardProps {
  readonly tale?: Tale
  readonly closeInstructions: () => void
  readonly onDonePressed: () => void
}

const InstructionsCard: React.FC<InstructionsCardProps> = ({
  tale,
  onDonePressed,
  closeInstructions,
}) => {
  return (
    <SecondaryBackgroundContainer>
      <Card
        onBottomButtonPressed={onDonePressed}
        contentButton={
          tale && (
            <CircleButton
              backgroundColor="#2D6A4B"
              backgroundColorActive="#2D6A4B"
              onClick={closeInstructions}
            >
              <Icon src={ContentImage} />
            </CircleButton>
          )
        }
        leaves
      >
          {tale && (
            <div>
              <SecondarySmallHeaderContainer>Szülői instrukció</SecondarySmallHeaderContainer>
              <TextContainer>{tale.instruction}</TextContainer>
            </div>
          )}
      </Card>
    </SecondaryBackgroundContainer>
  )
}

export default InstructionsCard
