import styled from 'styled-components'
import { BackgroundContainer } from '../../styled-components'

export const Container = styled(BackgroundContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const Header = styled.div`
  overflow: hidden;
  padding-top: 10px;
  position: relative;
  border-radius: inherit;
`

export const Logo = styled.img`
  width: 175px;
`
export const Menu = styled.div`
`

export const StyledInput = styled.input`
  width: 45%;
`
export const Footer = styled.div`
  margin-top: 64px;
`

export const StyledButton = styled.button`
  background: transparent;
  border: none;
  color: #9013fe;
  font-weight: bold;
  font-family: 'Maven Pro';
  width: 100px;
  cursor: pointer;
`

export const StyledImg = styled.img`
  width: 50px;
`
