import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { TaleRouteParamProps, TaleState } from './interfaces'
import taleApi from '../../../model/api/tale-api'
import Tale from '../../../model/domain/tale'
import ContentCard from './components/content-card'
import InstructionsCard from './components/instructions-card'
import DoneCard from './components/done-card'

const TaleList: React.FC = () => {
  const { id } = useParams<TaleRouteParamProps>()
  const [tale, setTale] = useState<Tale>()
  const [taleState, setTaleState] = useState<TaleState>(TaleState.CONTENT)
  const history = useHistory()

  useEffect(() => {
    ;(async function () {
      const newTale = await taleApi.getTaleById(parseInt(id, 0))
      if (newTale) {
        setTale(newTale)
      } else {
        history.goBack()
      }
    })()
  }, [id, history])

  const openInstructions = () => {
    setTaleState(TaleState.INSTRUCTION)
  }

  const closeInstructions = () => {
    setTaleState(TaleState.CONTENT)
  }

  const onDonePressed = () => {
    setTaleState(TaleState.DONE)

    // Remove this to have "done" screen as well
    history.goBack()
  }

  const onTaleFinished = () => {
    history.goBack()
  }

  switch (taleState) {
    case TaleState.CONTENT:
      return (
        <ContentCard
          tale={tale}
          onDonePressed={onDonePressed}
          openInstructions={openInstructions}
        />
      )
    case TaleState.INSTRUCTION:
      return (
        <InstructionsCard
          tale={tale}
          onDonePressed={onDonePressed}
          closeInstructions={closeInstructions}
        />
      )
    case TaleState.DONE:
      return <DoneCard tale={tale} onTaleFinished={onTaleFinished} />
    default:
      return null
  }
}

export default TaleList
