export const ROUTE_ID = '/tale/:id'
export const ROUTE_TEMPLATE = (id: number) => `/tale/${id}`

export interface TaleRouteParamProps {
  readonly id: string
}

// eslint-disable-next-line no-shadow
export enum TaleState {
  CONTENT = 'CONTENT',
  INSTRUCTION = 'INSTRUCTION',
  DONE = 'DONE',
}
