import React from 'react'
import {
  TertiaryBackgroundContainer,
  SmallHeaderContainer,
  TextContainer,
} from '../../styled-components'
import Card from '../../card'
import { useHistory } from 'react-router-dom'

const Onboarding: React.FC = () => {
  const history = useHistory()

  return (
    <TertiaryBackgroundContainer>
      <Card title="Bevezető" leaves onBottomButtonPressed={() => history.goBack()}>
        <SmallHeaderContainer>Zsebmanó története</SmallHeaderContainer>
        <TextContainer>
          Egyszer régen, nagyon régen, mikor még az ükapám is csak akkorka volt, mint egy tarkabab,
          született egy kismanó. Olyan pöttömke volt, hogy ha nagyobb szél kerekedett, felkapta,
          mint egy virágmagot és úgy röptette egyik tisztásról a másikra. Szegény mamája nem győzte
          folyton keresni, ezért inkább a köténye zsebébe rejtette, míg egy kicsit meg nem
          erősödött. De ezután is mindenki csak Zsebmanónak szólította, hiába múlt el már 326 éves
          is. A segítőmanók nemzetségéhez tartozott, mint azok a társai, akik az óvodákban, a
          játszótereken és az otthonaikban keresték fel az embergyerekeket, hogy megkönnyítsék a
          napjaikat. Jó megfigyelő volt, azt is észrevette, hogy minden ember körül színes buborékok
          lebegnek, amik számukra láthatatlanok. Ám Zsebmanó jól látta mindet és a színükből azt is
          pontosan meg tudta mondani, kinek milyen kedve van. A jókedvűeknek szivárványos lila vagy
          arany buborékja volt. De a rosszkedvűeknek, unatkozóknak olyan zavaros zöld, mint a 120
          napos záptojás. Hű, még nézni is rosszul esett neki. Ilyenkor azonnal kitalált valami
          izgalmas játékot vagy érdekes történetet, amivel felvidíthatta őket. Szeretett volna még
          ügyesebben és gyorsabban segíteni, mint a manópajtásai. Ezért valami egészen új és
          káprázatos dolgot talált ki: mostantól ő bizony meglovagolja a rádióhullámokat, hogy olyan
          sebesen szállhasson, mint a gondolat! Valahányszor unatkozó kisgyereket lát, hipp-hopp ott
          terem majd, hogy a vele lévő felnőtt telefonjára varázsolja jókedv hozó ötleteit. Így ha
          elő akarja hívni valaki, csak meg kell érintenie Zsebmanó képét a kijelzőn, és máris tudni
          fogja, mit kell tennie, hogy vidámabban teljenek a percei. Aki vele tart, az végleg búcsút
          mondhat az unalmas várakozásnak, az egyhangú gyaloglásnak, járművön zötykölődésnek. No meg
          a záptojás zöld buborékoknak! Minden gyerek körül aranyszín ködök úsznak és mosolyok
          röpködnek majd. De ez még hagyján! Képzeld! A fejébe vette, hogy összeköti az emberek
          világát a manókéval. Így valahányszor egy kisgyerek felvidul a segítségével, Manóföldön
          rögtön valami csudálatos dolog történik. Megjavulnak az elromlott dolgok, megmenekülnek a
          bajbajutottak. Remek ötlet, nem gondolod? Kíváncsi vagy, hogy működik? Ha szeretnéd
          kipróbálni Zsebmanó játékait vagy szívesen segítenél a manóföldi kalamajkák
          kigubancolásában, szólj a szüleidnek, hogy nyomják meg a varázsgombot a telefonjukon. Így
          Zsebmanó mindig meg tudja majd üzenni neked, hogy épp mi történt náluk, és mit tegyél,
          hogy segíthess a manóknak. Ugye milyen izgalmas? És ez valóság! Nem mese! Gyere, legyél
          részese!
        </TextContainer>
      </Card>
    </TertiaryBackgroundContainer>
  )
}

export default Onboarding
