import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  background: #ffffff;
  text-align: left;
`

export const CategoryNameContainer = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 35px;
  color: #8421f4;
`

export const TaleLinkContainer = styled.div`
  margin-top: 24px;
`

export const TaleLink = styled(Link)`
  font-family: Open Sans;
  font-style: italic;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #2d6a4b;
  text-decoration: none;
`
