import Tale from '../../../../model/domain/tale'
import InfoImage from '../../../../assets/info.png'
import React from 'react'
import {
  SecondaryBackgroundContainer,
  SmallHeaderContainer,
  TextContainer,
  CircleButton,
  Icon,
} from '../../../styled-components'
import Card from '../../../card'

interface ContentCardProps {
  readonly tale?: Tale
  readonly openInstructions: () => void
  readonly onDonePressed: () => void
}

const ContentCard: React.FC<ContentCardProps> = ({ tale, onDonePressed, openInstructions }) => {
  return (
    <SecondaryBackgroundContainer>
      <Card
        onBottomButtonPressed={onDonePressed}
        instructionsButton={
          tale?.instruction && (
            <CircleButton
              backgroundColor="#2D6A4B"
              backgroundColorActive="#2D6A4B"
              onClick={openInstructions}
            >
              <Icon src={InfoImage} />
            </CircleButton>
          )
        }
        leaves
      >
        {tale && (
          <div>
            <SmallHeaderContainer>{tale.title}</SmallHeaderContainer>
            <TextContainer>{tale.content}</TextContainer>
          </div>
        )}
      </Card>
    </SecondaryBackgroundContainer>
  )
}

export default ContentCard
