import styled from 'styled-components'
import Leaf from '../../assets/level.png'

export const Container = styled.div`
  border-radius: 24px;
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
  overflow: hidden;
`
export const Header = styled.div`
  width: 100%;
  height: ${props => (props.title ? '85px' : '54px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Title = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 36px;
  color: #fff;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  margin: 0;
`

export const FlipCardContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: inherit;
  position: relative;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
`

export const FlipCard = styled.div`
  background-color: #fff;
  border-radius: inherit;
  position: relative;
  width: 320px;
  height: 535px;
`

export const FlipCardContent = styled.div`
  box-sizing: border-box;
  margin: 30px 10px 40px 15px;
  height: 465px;
  overflow-y: auto;
  padding-right: 5px;
`

export const GradientBottom = styled.div`
  border-radius: inherit;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
`

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: -30px;
  left: 0px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const InstructionsButtonContainer = styled.div`
  position: absolute;
  top: 35px;
  left: 13px;
  width: 60px;
  height: 100px;
`

export const ContentButtonContainer = styled.div`
  position: absolute;
  top: 35px;
  right: 13px;
  width: 60px;
  height: 100px;
`

export const RightLeaf = styled.div`
  position: absolute;
  top: 170px;
  right: -40px;
  width: 60px;
  height: 100px;
  display: flex;
  background-image: url(${Leaf});
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(-30deg);
`

export const LeftLeaf = styled.div`
  position: absolute;
  top: 430px;
  left: -40px;
  width: 60px;
  height: 100px;
  background-image: url(${Leaf});
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(30deg);
`
