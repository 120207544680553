import styled from 'styled-components'
import ZfImage from '../assets/zf.png'
import ZoldFoltImage from '../assets/zold-folt.png'

export const CardContainer = styled.div`
  background: #ffffff;
  text-align: left;
  width: 100%;
`

export const SmallHeaderContainer = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: #2d6a4b;
  margin-top: 24px;
`

export const SecondarySmallHeaderContainer = styled(SmallHeaderContainer)`
  color: #8421f4;
`

export const TextContainer = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: black;
  margin-top: 16px;
  text-align: justify;
`

export const TextButton = styled.button`
  width: 150px;
  height: 30px;
  background-color: #8421f4;
  border-radius: 24px;
  border-width: 0px;
  cursor: pointer;

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  color: white;
  text-align: center;
  text-decoration: none;

  &:active {
    background-color: rgba(144, 19, 254);
  }
`

export const CircleButton = styled.button<{
  backgroundColor?: string
  backgroundColorActive?: string
}>`
  width: 60px;
  height: 60px;
  background-color: ${props => props.backgroundColor ?? '#8421f4'};
  border-radius: 50%;
  border-width: 0px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: Open Sans;
  font-weight: bold;
  font-size: 35px;
  color: white;
  text-align: center;

  &:active {
    background-color: ${props => props.backgroundColorActive ?? 'rgba(144, 19, 254)'};
  }
`

export const BackgroundContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: #d0eeb2;
  background-image: url(${ZoldFoltImage});
  background-size: 520px;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: -400px;

  @media (min-width: 500px) {
    border-radius: 15px;
  }
`
export const SecondaryBackgroundContainer = styled(BackgroundContainer)`
  background-image: url(${ZfImage});
  background-position: center;
  background-size: cover;
`

export const TertiaryBackgroundContainer = styled(SecondaryBackgroundContainer)`
  background-color: rgba(144, 19, 254);
  background-image: url(${ZfImage});
`


export const Icon = styled.img`
  width: 32px;
`
